import { Col, Row } from "react-bootstrap"
import TicketsContext from "../store/TicketsContext"
import TicketCard from "./TicketCard"
import { useContext } from "react"

const TicketsLayout = () => {
    const ticketsContext = useContext(TicketsContext)

    return (
        <Row>
            {Object.keys(ticketsContext.tickets).length ? Object.entries(ticketsContext.tickets).map(
                ([_id, ticket]) => <Col key={_id} xs={12} md={4} lg={3}><TicketCard ticket={ticket} /></Col>
            ) : (
                <div style={{ 
                    height: '50vh', 
                    width: '100vw', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                }}>
                    <h2>No tickets were found!</h2>
                </div>
            )}
        </Row>
    )
}

export default TicketsLayout