import { Button, Container } from "react-bootstrap";
import TicketsContext from "./store/TicketsContext";
import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const HomePage = () => {
    const {setErrorMessage, setSuccessMessage} = useContext(TicketsContext)
    const location = useLocation();

    useEffect(() => {
        setErrorMessage(null)
        setSuccessMessage(null)
    }, [location, setErrorMessage, setSuccessMessage]);

    return (
        <Container className="d-flex flex-column align-items-center justify-content-center w-50 mx-auto" style={{ height: '100vh' }}>
            <h1 className="my-5"><img src="/logo192.png" alt="logo" width="50px" /> HomePage</h1>
            <Link to="/employee-dashboard">
                <Button variant="secondary" className="mb-3 w-100">
                    Dashboard impiegato
                </Button>
            </Link>
            <Link to="/tickets/new">
                <Button variant="primary" className="mb-3 w-100">
                    Crea biglietto
                </Button>
            </Link>
        </Container>
    )
}

export default HomePage