import React from 'react';

import { Ticket, TicketPayloadCreate, TicketPayloadUpdate, TicketType } from '../utils/types';

export interface SearchFormValues {
    first_name?: string,
    last_name?: string,
    email?: string,
    ticket_type_id?: string,
    field_1_name?: string, 
    field_1_value?: string, 
    field_2_name?: string, 
    field_2_value?: string, 
}

interface TicketsContextObject {
    setSuccessMessage: React.Dispatch<React.SetStateAction<string | null>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    successMessage: string | null;
    errorMessage: string | null;
    typesLoaded?: boolean,
    tickets: Record<string, Ticket>,
    ticketTypes: Record<string, TicketType>,
    init(): Promise<void>,
    searchTickets(filterValues: SearchFormValues): Promise<[boolean, string]>
    deleteTicket(ticketId: string): Promise<[boolean, string]>,
    createTicket(ticket: TicketPayloadCreate): Promise<[boolean, string]>,
    updateTicket(ticketId: string, ticket: TicketPayloadUpdate): Promise<[boolean, string]>
}

const TicketsContext = React.createContext<TicketsContextObject>({
    successMessage: '',
    errorMessage: '',
    setSuccessMessage: () => {},
    setErrorMessage: () => {},
    tickets: {},
    typesLoaded: undefined,
    ticketTypes: {},
    init() {
        return Promise.resolve()
    },
    searchTickets(filterValues: SearchFormValues) {
        return Promise.resolve([true, ''])
    },
    deleteTicket(ticketId: string) {
        return Promise.resolve([true, ''])
    },
    createTicket(ticket: TicketPayloadCreate) {
        return Promise.resolve([true, ''])
    },
    updateTicket(ticketId: string, ticket: TicketPayloadUpdate) {
        return Promise.resolve([true, ''])
    }
});

export default TicketsContext;
