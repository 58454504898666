import TicketsContext, { SearchFormValues } from './TicketsContext';
import { useState } from 'react';

import type { Ticket, TicketPayloadCreate, TicketPayloadUpdate, TicketType } from '../utils/types';
import { Alert, Container } from 'react-bootstrap';

const { REACT_APP_API_DOMAIN: API_DOMAIN } = process.env

const TicketContextProvider: React.FC<{
  children: React.ReactNode[] | React.ReactNode;
}> = ({ children }) => {
  const [ticketTypes, setTicketTypes] = useState<Record<string, TicketType>>({});
  const [tickets, setTickets] = useState<Record<string, Ticket>>({});
  const [typesLoaded, setTypesLoaded] = useState<boolean | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  return (
    <TicketsContext.Provider
      value={{
        successMessage,
        errorMessage,
        setSuccessMessage,
        setErrorMessage,
        typesLoaded,
        tickets,
        ticketTypes,

        async init() {
          try {

            if (Object.keys(tickets).length === 0) {
              const res = await fetch(
                API_DOMAIN + '/tickets',
                {
                  headers: {
                    'Accept': 'application/json'
                  }
                }
              )

              if (res.status === 200) {
                const JSONres: Ticket[] = await res.json()
                const tickets: Record<string, Ticket> = {}
                for (const t of JSONres) {
                  tickets[t._id] = t
                }
                setTickets(tickets)
              }
            }

          } catch (e) {
            setTickets({})
          } 

          try {
            
            if(Object.keys(ticketTypes).length === 0) {
              const res = await fetch(
                API_DOMAIN + '/ticket-types',
                {
                  headers: {
                    'Accept': 'application/json'
                  }
                }
              )

              if (res.status === 200) {
                const JSONres: Record<string, TicketType> = await res.json()
                setTicketTypes(() => {
                  setTypesLoaded(true)
                  return JSONres
                })
              }
            }

          } catch (e) {
            setTicketTypes({})
          }
          
        },
        
        async searchTickets(searchFormValues: SearchFormValues) {
          const params = new URLSearchParams();

          const { 
            email, 
            first_name, 
            last_name, 
            ticket_type_id,
            field_1_name, 
            field_1_value, 
            field_2_name, 
            field_2_value 
          } = searchFormValues
          
          if (email) params.append('email', email)
          if (first_name) params.append('first_name', first_name)
          if (last_name) params.append('last_name', last_name)
          if (ticket_type_id) params.append('ticket_type_id', ticket_type_id)
          if (field_1_name) params.append('field_1_name', field_1_name)
          if (field_1_value) params.append('field_1_value', field_1_value)
          if (field_2_name) params.append('field_2_name', field_2_name)
          if (field_2_value) params.append('field_2_value', field_2_value)

          const res = await fetch(
            API_DOMAIN + '/tickets?' + params.toString(),
            {
              headers: {
                'Accept': 'application/json',
              }
            }
          )
          
          if (res.status === 200) {
            const JSONres: Ticket[] = await res.json()
            const tickets: Record<string, Ticket> = {}
            for (const t of JSONres) tickets[t._id] = t
            setTickets(tickets)
            setSuccessMessage(`${Object.keys(tickets).length} biglietti`)
            setErrorMessage(null)
            return [true, `${Object.keys(tickets).length} biglietti`]
          } else {
            const JSONres = await res.json()
            setErrorMessage(JSONres.message! as string)
            setSuccessMessage(null)
            return [false, JSONres.message! as string]
          }
        },

        async deleteTicket(ticketId: string) {
          const res = await fetch(
            API_DOMAIN + '/tickets/' + ticketId,
            {
              method: 'DELETE',
              headers: {
                'Accept': 'application/json'
              }
            }
          )
          if (res.status === 204) {
            setTickets(prev => {
              const newTickets: Record<string, Ticket> = {}; 
              for (const key in prev) {
                if (ticketId !== key) {
                  newTickets[key] = prev[key]
                }
              }
              return newTickets
            })
            setSuccessMessage('biglietto eliminato')
            setErrorMessage(null)
            return [true, 'biglietto eliminato']
          } else {
            const { message } = await res.json()
            setSuccessMessage(null)
            setErrorMessage(message)
            return [false, message]
          }
        },

        async createTicket(ticket: TicketPayloadCreate) {
          setSuccessMessage(null)
          setErrorMessage(null)
          const res = await fetch(API_DOMAIN + '/tickets', {
            method: 'POST',
            body: JSON.stringify(ticket),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
          const JSONres = await res.json()
          if (res.status === 201) {
            setTickets(prev => ({ 
              ...prev,
              [JSONres._id]: JSONres
            }))
            setSuccessMessage('biglietto creato')
            setErrorMessage(null)
            return [true, 'biglietto creato']
          }
          setErrorMessage(JSONres.message)
          setSuccessMessage(null)
          return [false, JSONres.message]
        },

        async updateTicket(ticketId: string, ticket: TicketPayloadUpdate) {
          const res = await fetch(`${API_DOMAIN}/tickets/${ticketId}`, {
            method: 'PUT',
            body: JSON.stringify(ticket),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          })
          
          const JSONres = await res.json()
          
          if (res.status === 200) {
            setTickets(prev => {
              const newTickets: Record<string, Ticket> = {}; 
              for (const key in prev) {
                if (ticketId !== key) {
                  newTickets[key] = prev[key]
                } else {
                  newTickets[key] = JSONres
                }
              }
              return newTickets
            })
            setErrorMessage(null)
            setSuccessMessage('Biglietto aggiornato')
            return [true, 'Biglietto aggiornato']
          }
          setSuccessMessage(null)
          setErrorMessage(JSONres.message)
          return [false, JSONres.message]
        },
      }}
    >
      <Container>
        {successMessage && <Alert variant='success' className='my-4'>{successMessage}</Alert>}
        {errorMessage && <Alert variant='danger' className='my-4'>{errorMessage}</Alert>}
        {children}
      </Container>
    </TicketsContext.Provider>
  );
};

export default TicketContextProvider;
