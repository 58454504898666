import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useContext, useEffect } from "react";

import EmployeeDashboard from "./EmployeeDashboard";
import TicketVisualization from "./TicketVisualization";
import TicketsContext from "./store/TicketsContext";
import UpdateTicketForm from "./UpdateTicketForm";
import NewTicketForm from "./NewTicketForm";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./HomePage";

const App = () => {
    const router = createBrowserRouter([
        // only for testing
        {
          path: '/',
          children: [
            {
              path: '',
              element: <HomePage />,
            },
            {
              path: 'tickets',
              children: [
                  {
                    path: "new",
                    element: <NewTicketForm />,
                  },
                  {
                    path: ":ticketId",
                    children: [
                      {
                        path: "edit",
                        element: <UpdateTicketForm />,
                      },
                      {
                        path: '',
                        element: <TicketVisualization />,
                      },
                    ]
                  },
                ]
              },
              {
                path: '/employee-dashboard',
                element: <EmployeeDashboard />
              }

          ]
        },
    ]);
    const ticketContext = useContext(TicketsContext)

    useEffect(() => {
        ticketContext.init()
    }, [ticketContext])

    return <RouterProvider router={router}/>
}

export default App