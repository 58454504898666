import TicketsContext from "../store/TicketsContext"
import { Button, Container } from "react-bootstrap"
import TicketSearchForm from "./TicketSearchForm"
import { useContext, useEffect } from "react"
import TicketsLayout from "./TicketsLayout"
import { Link, useLocation } from "react-router-dom"

const EmployeeDashboard = () => {
  const {setSuccessMessage, setErrorMessage} = useContext(TicketsContext)
  const location = useLocation()
  
  useEffect(() => {
    setErrorMessage(null)
    setSuccessMessage(null)
}, [location, setErrorMessage, setSuccessMessage]);

  return (
    <Container className="mt-3">
      <Link to="/">
        <Button variant="primary">
          Home Page
        </Button>
      </Link>
      <h1 className="text-center my-5">Dashboard impiegato</h1>
      <TicketSearchForm />
      <TicketsLayout />
    </Container>
  )
}

export default EmployeeDashboard