import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap"
import TicketsContext, { SearchFormValues } from "../store/TicketsContext"
import React, { useContext, useState } from "react"

const SearchTickets = () => {
  const ticketsContext = useContext(TicketsContext)
  const [formState, setFormState] = useState<SearchFormValues>({
    first_name: '',
    last_name: '',
    email: '',
    ticket_type_id: '',
    field_1_name: '',
    field_1_value: '',
    field_2_name: '',
    field_2_value: '',
  })


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await ticketsContext.searchTickets(formState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormState(prev => {
      const hasTicketTypeIdChanged = e.target.name === 'ticket_type_id' && e.target.value !== prev.ticket_type_id

      return {
        ...prev,
        field_1_name: hasTicketTypeIdChanged ? '' : prev.field_1_name,
        field_2_name: hasTicketTypeIdChanged ? '' : prev.field_2_name,
        field_1_value: hasTicketTypeIdChanged ? '' : prev.field_1_value,
        field_2_value: hasTicketTypeIdChanged ? '' : prev.field_2_value,
        [e.target.name]: e.target.value,
      }
    })
  }

  return (
    <Container className="my-3">
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <FormLabel htmlFor="name_input">Nome</FormLabel>
              <Form.Control type="text" id="name_input" name="first_name" value={formState.first_name} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="lastname_input">Cognome</Form.Label>
              <Form.Control type="text" id="lastname_input" name="last_name" value={formState.last_name} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="email_input">email</Form.Label>
              <Form.Control type="text" id="email_input" name="email" value={formState.email} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="ticket_type_name">Nome tipo biglietto</Form.Label>
              <Form.Select name="ticket_type_id" id="ticket_type_name" defaultValue="" onChange={handleChange}>
                {Object.entries(ticketsContext.ticketTypes)
                  .map(([id, tt]) => <option value={id} key={id}>{tt.name}</option>
                )}
                <option value="">None</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" hidden={!formState.ticket_type_id}>
              <Form.Label htmlFor="field_1_name">Nome campo 1</Form.Label>
              <Form.Select id="field_1_name" name="field_1_name" onChange={handleChange} defaultValue="">
                {formState.ticket_type_id && Object.keys(ticketsContext.ticketTypes[formState.ticket_type_id].schema).map(fieldName => (
                  <option value={fieldName} key={fieldName}>{fieldName}</option>
                ))}
                <option value="">None</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" hidden={!formState.ticket_type_id}>
              <Form.Label htmlFor="field_1_value">Valore campo 1</Form.Label>
              <Form.Control type="text" id="field_1_value" name="field_1_value" value={formState.field_1_value} onChange={handleChange} disabled={!formState.field_1_name!.length} />
            </Form.Group>

            <Form.Group className="mb-3" hidden={!formState.ticket_type_id}>
              <Form.Label htmlFor="field_2_name">Nome campo 2</Form.Label>
              <Form.Select id="field_2_name" name="field_2_name" onChange={handleChange} defaultValue="">
                {formState.ticket_type_id && Object.keys(ticketsContext.ticketTypes[formState.ticket_type_id].schema).map(fieldName => (
                  <option value={fieldName} key={fieldName}>{fieldName}</option>
                ))}
                <option value="">None</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" hidden={!formState.ticket_type_id}>
              <Form.Label htmlFor="field_2_value">Valore campo 2</Form.Label>
              <Form.Control type="text" id="field_2_value" name="field_2_value" value={formState.field_2_value} onChange={handleChange} disabled={!formState.field_2_name!.length} />
            </Form.Group>

            <Button type="submit" className="w-100">Cerca Biglietti</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default SearchTickets
