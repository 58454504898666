import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import TicketsContext from "./store/TicketsContext"
import { TicketPayloadCreate } from "./utils/types"
import { Link, useLocation } from "react-router-dom"

const NewTicketForm = () => {
  const [formData, setFormData] = useState<Record<string, string>>({
    first_name: '',
    last_name: '',
    email: '',
    ticket_type_id: '',
  })
  const [ticketFields, setTicketFields] = useState<Record<string, string>>({})

  const { setSuccessMessage, setErrorMessage, ...ticketContext } = useContext(TicketsContext)

  let location = useLocation();

  useEffect(() => {
      setSuccessMessage(null)
      setErrorMessage(null)
  }, [location, setSuccessMessage, setErrorMessage]);


  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (!e.target.name.includes('.')) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    } else {
      setTicketFields(prev => ({
        ...prev,
        [e.target.name.split('.').at(-1)!]: e.target.value
      }))
    } 

    if (e.target.name === 'ticket_type_id') {
      const fields: Record<string, string> = {}
      for (const name of Object.keys(ticketContext.ticketTypes[e.target.value].schema)) {
        fields[name] = ''
      }
      setTicketFields(fields)
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    await ticketContext.createTicket({ ...formData, fields: ticketFields } as TicketPayloadCreate)
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col xs={1} md={3} />
        <Col xs={10} md={6}>
          <Link to="/">
            <Button variant="primary">
              Home Page
            </Button>
          </Link>
          <Form onSubmit={handleSubmit}>
            <h1 className="my-2">Crea biglietto</h1>
            {
              ticketContext.typesLoaded !== undefined && !ticketContext.typesLoaded ? (
                <Alert variant="danger">Nessun tipo di biglietto creato! Impossibile creare un biglietto.</Alert>
              ) : (
                <>
                  <Form.Group className="mt-3">
                    <Form.Label htmlFor="ticket_type_id">Nome tipo biglietto</Form.Label>
                    <Form.Select name="ticket_type_id" id="ticket_type_id" defaultValue="none" onChange={handleChange}>
                      {Object.entries(ticketContext.ticketTypes)
                        .map(([id, tt]) => <option value={id} key={id}>{tt.name}</option>
                      )}
                      <option value="none">None</option>
                    </Form.Select>
                  </Form.Group>

                  {formData.ticket_type_id && (
                    <>
                      <Form.Group className="mt-3">
                        <Form.Label htmlFor="email">Email:</Form.Label>
                        <Form.Control type="text" id="email" name="email" onChange={handleChange} value={formData.email} />
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label htmlFor="first_name">Nome:</Form.Label>
                        <Form.Control type="text" id="first_name" name="first_name" onChange={handleChange}  value={formData.first_name}/>
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label htmlFor="last_name">Cognome:</Form.Label>
                        <Form.Control type="text" id="last_name" name="last_name" onChange={handleChange}  value={formData.last_name}/>
                      </Form.Group>

                      {Object.keys(ticketFields).map((fieldName) => (
                        <Form.Group key={fieldName} className="mt-3">
                          <Form.Label htmlFor={`${fieldName}-input`}>
                            {fieldName} 
                          </Form.Label>
                          <Form.Control type="text" id={`${fieldName}-input`} name={`fields.${fieldName}`} value={ticketFields[fieldName]} onChange={handleChange} />
                        </Form.Group>
                      ))}

                      <Form.Group className="mt-3">
                        <Form.Label htmlFor="e-mod-p">Stampa Biglietto:</Form.Label>
                        <Form.Check type="radio" name="e-mod" id="e-mod-p" defaultChecked={true} />
                        <Form.Label htmlFor="e-mod-e">Invia Biglietto per Email:</Form.Label>
                        <Form.Check type="radio" name="e-mod" id="e-mod-e" />
                      </Form.Group>

                      <Button variant="success" className="w-100 my-3" type="submit">Crea biglietto</Button>
                    </>
                  )}
                </>
              )
            }

            
          </Form>
        </Col>
        <Col xs={1} md={3} />
      </Row>
    </Container>
  )
}

export default NewTicketForm