import { Button, Col, Container, Placeholder, Row } from "react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import type { APIErrorResponse, Ticket } from "./utils/types";
import TicketsContext from "./store/TicketsContext";

const { REACT_APP_API_DOMAIN: API_DOMAIN } = process.env

const TicketVisualization = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const [errorAlert, setErrorAlert] = useState<string>('')
  const [showCodes, setShowCodes] = useState(false)
  const location = useLocation()

  const fetchTicket = useCallback(async () => {
    const res = await fetch(API_DOMAIN + `/tickets/${ticketId}`)
    const json = await res.json() as APIErrorResponse | Ticket

    if (res.status !== 200) {
      setErrorAlert((json as APIErrorResponse).message)
      return
    }

    setTicket(json as Ticket)
  }, [ticketId])

  useEffect(() => {
    fetchTicket()
  }, [ticketId, fetchTicket])


  const {setSuccessMessage, setErrorMessage } = useContext(TicketsContext)

  useEffect(() => {
    setErrorMessage(null)
    setSuccessMessage(null)
  }, [location, setErrorMessage, setSuccessMessage]);


  return (
    <Container fluid>
      <Row className="d-flex justify-content-center">
        <Col 
          xs={{ span: 0 }} 
          md={{ span: 3 }} 
          lg={{ span: 4 }} 
        />
        <Col 
          mx="auto"
          xs={{ span: 12 }} 
          md={{ span: 6 }} 
          lg={{ span: 4 }}
          >
          {
            errorAlert ? (
              <h1>{errorAlert}</h1>
            ) : ticket ? (
              <>
                <h1 className="my-5 text-aling-center">Biglietto</h1>
                <div style={{ fontSize: '1.2rem' }}>
                  Intestatario: {ticket.first_name} {ticket.last_name}
                </div>
                <br/>
                <div style={{ fontSize: '1.2rem' }}>
                  Email: {ticket.email}
                </div>
                <br/>
                <section>
                  {
                    showCodes 
                    ? 
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={ticket.QR_url} alt="QR code" style={{ height: '40vh' }} />
                        <h1 className="my-3" style={{ height: '5vh' }}>{ticket.alphanumeric_code}</h1>
                      </div>
                    :
                    <div>
                      <Placeholder bg="gray" className="w-100 rounded-3" style={{ height: '40vh' }} />
                      <Placeholder bg="gray" className="w-100 rounded-3 my-3" style={{ height: '5vh' }} />
                    </div>
                  }
                  <Button color="primary" className="w-100" onClick={() => setShowCodes(prev => !prev)}>{showCodes ? 'Nascondi' : 'Mostra'} codici</Button>
                </section> 
                <section className="my-3" style={{ fontSize: '1.2rem' }}>
                  <div>
                    Caratteristiche biglietto
                  </div>
                    <ul>
                        {Object.entries(ticket.fields).map(([name, val]) => <li key={name}>{name}: {String(val)}</li>)}
                    </ul>
                </section>
              </>
            ) : (
              <h1>Loading...</h1>
            )
          }
        </Col>
        <Col 
          xs={{ span: 0 }} 
          md={{ span: 3 }} 
          lg={{ span: 4 }} 
        />
      </Row> 
    </Container>
  )
}

export default TicketVisualization;