import TicketsContext from "../store/TicketsContext"
import { useContext } from "react"

import type { Ticket } from "../utils/types"
import { Button, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const TicketCard = (props: { ticket: Ticket }) => {
  const ticketsContext = useContext(TicketsContext)
  const navigate = useNavigate()

  const handleDeleteTicket = () => ticketsContext.deleteTicket(props.ticket._id)
  const handleUpdateTicket = () => navigate(`/tickets/${props.ticket._id}/edit`)

  return (
    <Card className="mb-3">
      <Card.Body>
        <div>First name: {props.ticket.first_name}</div>
        <div>Last name: {props.ticket.last_name}</div>
        <div>Email: {props.ticket.email}</div>
        <div>Validation code: {props.ticket.alphanumeric_code}</div>
        {Object.keys(ticketsContext.ticketTypes).length && (
          <div>Type: {ticketsContext.ticketTypes[props.ticket.ticket_type_id].name}</div>
        )}
        <ul>
          {Object.entries(props.ticket.fields).map(([name, val]) => <li key={name}>{name}: {String(val)}</li>)}
        </ul>
        <Button variant="danger" onClick={handleDeleteTicket}>Delete</Button>
        <Button variant="warning" className="mx-3" onClick={handleUpdateTicket}>Update</Button>
        
      </Card.Body>
    </Card>
  )
}

export default TicketCard