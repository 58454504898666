import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import TicketsContext from "./store/TicketsContext"
import { TicketPayloadUpdate } from "./utils/types"
import { useParams, Link, useLocation } from "react-router-dom"

const NewTicketForm = () => {
  const { ticketId } = useParams() as { ticketId: string }

  const [formData, setFormData] = useState<Record<string, string>>({
    first_name: '',
    last_name: '',
    email: '',
  })
  const [ticketFields, setTicketFields] = useState<Record<string, string>>({})

  const { setSuccessMessage, setErrorMessage, ...ticketContext } = useContext(TicketsContext)

  let location = useLocation();

  useEffect(() => {
    setSuccessMessage(null)
    setErrorMessage(null)
}, [location, setErrorMessage, setSuccessMessage]);

  useEffect(
    () => {
      const ticket = ticketContext.tickets[ticketId]

      if (ticket) {
        setFormData(() => {
          return {
            first_name: ticket.first_name,
            last_name: ticket.last_name,
            email: ticket.email,
          }
        })
  
        setTicketFields(() => {
          return ticket.fields
        })
      } 
    }, 
    [ticketId, setFormData, setTicketFields, ticketContext.ticketTypes, ticketContext.tickets]
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (!e.target.name.includes('.')) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    } else {
      setTicketFields(prev => ({
        ...prev,
        [e.target.name.split('.').at(-1)!]: e.target.value
      }))
    } 
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    await ticketContext.updateTicket(
      ticketId!, 
      { ...formData, fields: ticketFields } as TicketPayloadUpdate
    )
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col xs={1} md={3} />
        <Col xs={10} md={6}>
            <Link to="/">
              <Button variant="primary">
                Home Page
              </Button>
            </Link>
            {Object.keys(formData).length && Object.keys(ticketFields).length ? (
              <Form onSubmit={handleSubmit}>

                <h1 className="my-2">Modifica biglietto</h1>
                      
                <Form.Group className="mt-3">
                  <Form.Label htmlFor="email">Email:</Form.Label>
                  <Form.Control type="text" id="email" name="email" onChange={handleChange} value={formData.email} />
                </Form.Group>
      
                <Form.Group className="mt-3">
                  <Form.Label htmlFor="first_name">Nome:</Form.Label>
                  <Form.Control type="text" id="first_name" name="first_name" onChange={handleChange}  value={formData.first_name}/>
                </Form.Group>
      
                <Form.Group className="mt-3">
                  <Form.Label htmlFor="last_name">Cognome:</Form.Label>
                  <Form.Control type="text" id="last_name" name="last_name" onChange={handleChange}  value={formData.last_name}/>
                </Form.Group>
      
                {Object.keys(ticketFields).map((fieldName) => (
                  <Form.Group key={fieldName} className="mt-3">
                    <Form.Label htmlFor={`${fieldName}-input`}>
                      {fieldName} 
                    </Form.Label>
                    <Form.Control type="text" id={`${fieldName}-input`} name={`fields.${fieldName}`} value={ticketFields[fieldName]} onChange={handleChange} />
                  </Form.Group>
                ))}
      
                <Form.Group className="mt-3">
                  <Form.Label htmlFor="print-check">
                    Stampa biglietto
                  </Form.Label>
                  <Form.Check type="checkbox" id="print-check" />
                </Form.Group>
      
                <Button variant="warning" className="my-3 w-100" type="submit">Modifica biglietto</Button>
              </Form>
            ) : (
              <h1>404 Ticket not found!</h1>
            )}
            
        </Col>
        <Col xs={1} md={3} />
      </Row>
    </Container>
  )
}

export default NewTicketForm;